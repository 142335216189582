import { createStore } from 'vuex'
import mutations from './mutations'
import actions from './actions'

const Store = createStore({
    state: {
        nsrUserModel:{
            mryh: "",
            merchant_name: "",
            merchant_id: "",
        },
        nsrUserArr:[],
        SPXX:{
            spmc: "",
            kpje: "",
            slv: "",
            ggxh: "",
            sl: "",
            dw: "",
            hsdj: ""
        },
        sbztxx:{},
        sykpxx:{},
    },
    mutations,
    actions,
    modules: {
    }
});

export default Store;
