import wx from 'weixin-js-sdk';
import loginRequest from '../api/login'
import {getLocalStorage, setLocalStorage, removeLocalStorage} from '@/utils/local-storage.js'
import config from '../config'
import utils from '../plugins/utils'


export default function (router, store) {
    let c = router.query.c;
    if(c == undefined || c == "" || c == null){
        c=utils.getUrlParam('c');
    }
    // let p = router.query.p;      //判断登录设备是iOS、android或其他
    // if(p == undefined || p == "" || p == null){
    //     p=utils.getUrlParam('p');
    // }
    // sessionStorage.setItem("dlsb",JSON.stringify(p));
    if(window.__wxjs_environment === 'miniprogram'){
        wx.miniProgram.getEnv(function(res) {
            console.log(res.miniprogram) // true
        })
    }else{
        c = "test"  //测试
    }

   
    sessionStorage.setItem("isJianCe",'1'); //是否审核，0非，1是(隐藏)

    loginRequest.wx_get_user_info({c}).then((res=>{
        if (res.returnCode == "00"){

            let key = config.request.localKey;
            if (getLocalStorage(key)[key] !== null){
                removeLocalStorage(key);
            }
            sessionStorage.setItem('isDenglu',true);    //新用户登录05

            setLocalStorage({"Authorization": JSON.stringify(res.rows)});
            if (JSON.stringify(res.rows) != '{}'){
                let qyArr = res.rows.merchants;
                if(qyArr.length>0){
                    store.dispatch("setNSRARR", qyArr);
                    for (let i = 0; i < qyArr.length; i++){
                        let obj = qyArr[i];
                        if (res.rows.mryh === obj.merchant_id){
                            store.dispatch("setNSRXX", obj);
                        }
                    }
                }
                
                // 获取设备状态信息
                loginRequest.sb_states({}).then(res=>{
                    // console.log(res);
                    if (res.returnCode === "00"){
                        store.dispatch("setSBZT", res.rows);
                    }
                });
                // 获取开票信息
                loginRequest.yh_kpxx({}).then(res=>{
                    // console.log(res);
                    if (res.returnCode === "00"){
                        store.dispatch("setKPXX", res.rows.statistics);
                    }
                });
            }else{
                sessionStorage.setItem('isDenglu',false);
            }

        }else if(res.returnCode == "03" || res.returnCode == "05"){
            sessionStorage.setItem('isDenglu',false);
            setLocalStorage({"Authorization": 'null'});
        }else{
            sessionStorage.setItem('isDenglu',false);
            removeLocalStorage(config.request.localKey);
        }
    }));
}