export default {
    setNSRXX(context, param) {
        context.commit("setNSRXX", param);
    },
    setNSRARR(context, param) {
        context.commit("setNSRARR", param);
    },
    setSBZT(context, param) {
        context.commit("setSBZT", param);
    },
    setKPXX(context, param) {
        context.commit("setKPXX", param);
    }
}