import { createRouter, createWebHashHistory } from 'vue-router'
import { Toast } from 'vant';
import {getLocalStorage} from './utils/local-storage'
import config from './config'
const routes = [
    { path: '/', redirect: '/hull'},
    { path: '/fpgl', name: 'fpgl', meta: {title:'发票管理',showBar:false},
        component: () => import('./view/home/home.vue') },
    { name: 'invoiceAdd',  path: '/invoiceAdd', meta: { title: '添加发票明细', index: 3 },
        component: () => import('./view/invoice/invoiceAdd.vue'),  },
    { name: 'invoicing', path: '/invoicing', meta: { title: '发票开具', index: 2 },
        component: () => import('./view/invoice/invoicing.vue'), },
    { name: 'goodsAdd', path: '/goodsAdd', meta: { title: '商品管理', index: 4 },
        component: () => import('./view/invoice/goodsAdd.vue'), },
    { name: 'invoiceQuery', path: '/invoiceQuery', meta: { title: '发票查询', index: 2  },
        component: () => import('./view/invoice/invoiceQuery.vue'), },
    { name: 'login', path: '/login', meta: { title: '用户登录', showHeader: false },
        component: () => import('./view/login/login.vue'),},
    { name: 'custom', path: '/custom', meta: { title: '客户信息', index: 3  },
        component: () => import('./view/custom/custom.vue'), },
    { name: 'addCustom', path: '/addCustom', meta: { title: '客户管理', index: 4  },
        component: () => import('./view/custom/addCustom.vue'),},
    { name: 'commodity', path: '/commodity', meta: { title: '商品信息', index: 2},
        component: () => import('./view/commodity/commodity.vue'), },
    { name: 'compAdd', path: '/compAdd', meta: { title: '商品管理', index: 3},
        component: () => import('./view/commodity/compAdd.vue'), },
    { name: 'comdSpfl', path: '/comdSpfl', meta: { title: '商品类别', index: 4},
        component: () => import('./view/commodity/comdSpfl.vue'), },
    { name: 'invoiceDetail', path: '/invoiceDetail', meta: { title: '发票详情', index: 3  },
        component: () => import('./view/invoice/invoiceDetail.vue'), },
    { path: '/hull', name: 'hull', meta: {title:'企盈多企业财税服务平台',showBar:false}
        , component: () => import('./view/out/hull.vue') },
    { path: '/mine', name: 'mine', meta: {title:'我的信息',showBar:false}
        , component: () => import('./view/out/mine.vue') },
    { path: '/boss', name: 'boss', meta: {title:'',index: 2 }
        , component: () => import('./view/out/boss.vue') },
    { path: '/intellectBS', name: 'intellectBS', meta: {title:'智能办税',index: 2}
        , component: () => import('./view/out/intellectBS.vue') },
    { path: '/guidance', name: 'guidance', meta: {title:'智能指引',index: 2}
        , component: () => import('./view/out/guidance.vue') },
    { path: '/znzyList', name: 'znzyList', meta: {title:'智能指引详情信息',index: 3}
        , component: () => import('./view/out/znzyList.vue') },
    { path: '/sbmx', name: 'sbmx', meta: {title:'申报明细',index: 2}
        , component: () => import('./view/out/sbmx.vue') },
    { path: '/banner_show', name: 'banner_show', meta: {title:'',index: 2}
        , component: () => import('./view/out/banner_show.vue') },
    { path: '/wdgg', name: 'wdgg', meta: {title:'我的公告',index: 2}
        , component: () => import('./view/out/wdgg.vue') },
    { path: '/swtj', name: 'swtj', meta: {title:'税务体检',index: 2}
        , component: () => import('./view/swtj/index.vue') },
    { path: '/swtj_add', name: 'swtj_add', meta: {title:'新的体检',index: 2}
        , component: () => import('./view/swtj/add.vue') },
    { path: '/ptyhfwxy', name: 'ptyhfwxy', meta: {title:'平台用户协议',showBar:false}
        , component: () => import('./view/ptxy/ptyhfwxy.vue') },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    let t_auth = getLocalStorage(config.request.localKey);
    console.log(t_auth);
    if(to.path === "/banner_show" || to.path === "/mine" || to.path === "/guidance" || to.path === "/ptyhfwxy"){

    }else{
        let t_auth_infos = t_auth[config.request.localKey];
        console.log(t_auth_infos)

        if (t_auth_infos === null || t_auth_infos === "null"){
            if (to.path === "/login" || to.path === "/" || to.path === "/hull"){
                next();
            }else {
                if (from.path === "/login"){
                    return
                }
                // router.push({path: "/login"});
                Toast("请登录");
                return
            }
        }else{
            // t_auth_infos = JSON.parse(t_auth_infos);
            // if(t_auth_infos!=undefined&&t_auth_infos.length>0){
            //     let t_auth_info = t_auth_infos[0];
            //     console.log(t_auth_info);
            //     if(t_auth_info.yhlx == "sb"){
            //         if (to.path === "/fpgl" || to.path === "/invoicing"){
            //             Toast("暂未开通开票功能，请联系\n 赵老师 17686620204");
            //             return
            //         }
            //     }else{

            //     }
            // }
        }
    }
    next()
});

export default router
