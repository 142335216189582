import axios from 'axios'
import { Dialog,Toast } from 'vant';
import configer from  '../config'

let instance = axios.create({
    timeout: 450000, // 请求超时时间
    // baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
})

// http request 拦截器
instance.interceptors.request.use(
    // 请求时设置Token
    config => {
        if (!config.headers[configer.request.reqKey]) {
            // config.headers[configer.request.reqKey] = `${window.localStorage.getItem(
            //     configer.request.localKey
            // ) || ''}`;
        }
        config.url = 'api/' + config.url;
        console.log(config);
        return config
    },
    err => {
        Dialog({ message: err.response});
        return Promise.reject(err)
    })

// http response 拦截器
instance.interceptors.response.use(
    response => {
        //拦截响应，做统一处
        if (response.data.retcode == 1 || response.data.returnCode == "00"){
            if (process.env.NODE_ENV === 'development'){
                console.log("------------------------------------------");
                console.log(response.request.responseURL);
                console.log(response.data);
            }
            return response.data;
        }else if(response.data.returnCode == "05"){
            // Dialog({ message: response.data.returnMessage});
            return response.data;
        }else {
            //Dialog({ message: response.data.returnMessage });
            Toast(response.data.returnMessage);
            return response.data;
        }
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        console.log(error);
        Dialog({ message: '连接超时'});
        return Promise.reject(error.response) // 返回接口返回的错误信息
    })

export default instance
