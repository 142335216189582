import request from '../utils/request'

export default {
    wx_login(data) {
        return request({
            url: 'api/login/wx_login',
            method:'post',
            data
        })
    },
    wx_get_yzm() {
        return request({
            url: 'api/login/wx_get_yzm',
            method:'get',
        })
    },
    wx_get_user_info(data){
        return request({
            url: 'api/login/wx_get_user_info',
            method:'post',
            data
        })
    },
    wx_user_modify_mryh(data){
        return request({
            url: 'api/login/wx_user_modify_mryh',
            method:'post',
            data
        })
    },
    sb_states(data){
        return request({
            url: 'api/device/states',
            method:'post',
            data
        })
    },
    wx_user_modify_pwd(data){
        return request({
            url: 'api/login/wx_user_modify_pwd',
            method:'post',
            data
        })
    },
    wx_user_logout(data){
        return request({
            url: 'api/login/wx_user_logout',
            method:'post',
            data
        })
    },
    yh_kpxx(data) {
        return request({
            url: 'api/fpgl/statistics',
            method:'post',
            data
        })
    },
    yh_sbxx(data) {
        return request({
            url: 'api/sbxx/statistics',
            method:'post',
            data
        })
    },
    yh_qrsf(data) {
        return request({
            url: 'api/sbxx/confirmIdentity',
            method:'post',
            data
        })
    },
    yh_bdsf(data) {
        return request({
            url: 'api/sbxx/bindIdentity',
            method:'post',
            data
        })
    },
    znzy_list(data) {
        return request({
            url: 'api/znzy/list',
            method:'post',
            data
        })
    },
    sbmx_list(data) {
        return request({
            url: 'api/sbxx/statistics_mx',
            method:'post',
            data
        })
    },
    dsf_jysb(data) {
        return request({
            url: 'api/sbxx/dsf_yjsb',
            method:'post',
            data
        })
    },
    wx_user_modify_mrqy(data) {
        return request({
            url: 'api/login/wx_user_modify_mrqy',
            method:'post',
            data
        })
    },
    wx_user_logout_dsf(data) {
        return request({
            url: 'api/login/wx_user_logout_dsf',
            method:'post',
            data
        })
    },
}