<template>
    <van-nav-bar :title="title"
                 :left-text="index > 1? '返回': '' "
                 :left-arrow="index > 1? true: false "
                 :fixed="true"
                 :placeholder="true"
                 safe-area-inset-top
                 @click-left="goBack" v-show="showHeader"
                 v-if="showBar"
                />
</template>

<script>
    export default {
        name: "v-header",
        data() {
            return {
                title: "",
                path: "",
                index: 0,
            };
        },
        computed: {
            showHeader() {
                let _this = this;
                _this.title = document.title;
                _this.path = this.$route.path;
                _this.index = this.$route.meta.index;
                // console.log(this.$route.meta.showHeader);
                return this.$route.meta.showHeader == undefined ? true : false;
            },
            showBar(){
                // console.log(this.$route.meta.showBar);
                return this.$route.meta.showBar == undefined ? true : false;
            }
        },
        methods: {
            goBack() {
                // alert(this.$route.path+""+(this.$route.path == "/boss"));
                if(this.$route.path == "/boss"){
                  this.$router.replace({path: "/hull"});
                }else{
                  this.$router.back(-1);
                }
            },setTitle(t_title){
                this.title=t_title;
            }
        },
        
    }
</script>

<style lang="less">

</style>