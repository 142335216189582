import {
    NSR_USER_LOGIN,
    NSR_USER_LOGIN_DJXH,
    SPXX
} from './mutation-types'

export default {
    [NSR_USER_LOGIN](state, params){
        state.nsrUserModel = params;
    },
    [NSR_USER_LOGIN_DJXH](state, params){
        state.nsrUserModel.DJXH = params;
    },
    [SPXX](state, params){
        state.SPXX = params;
    },
    setNSRXX(state, params){
        state.nsrUserModel = params;
    },
    setNSRARR(state, params){
        state.nsrUserArr = params;
    },
    setSBZT(state, params){
        state.sbztxx = params;
    },
    setKPXX(state, params){
        state.sykpxx = params;
    },
}
