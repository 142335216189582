module.exports = {
    request:{
        // context: "http://127.0.0.1:8002",
        // context: "http://60.208.125.86:35005",
        context: "http://127.0.0.1:35005",
        reqKey: "X-Litemall-Token",
        localKey: "Authorization",
        pageSize: "10"
    },
    commonMes:{
        alertTitle: "温馨提示"
    }
}
