<template>
  <div id="app">
    <v-header v-if="showBar"></v-header>
    <keep-alive>
      <router-view class="view-router"  v-if="keepAlive"></router-view>
    </keep-alive>
    <router-view class="view-router" v-if="!keepAlive"></router-view>
    <router-view name="bar"></router-view>
  </div>
</template>

<script>
  import getUserInfo from '@/mixin/getUserInfo.js'
  import {defineComponent, computed, onMounted, getCurrentInstance,ref} from 'vue'
  import {useStore} from 'vuex';
  import header from "@/components/Header";


  export default defineComponent({
    	
		components:{
			'v-header': header
		},
      
		setup(){
			const {proxy} = getCurrentInstance();
			const keepAlive = computed(()=>proxy.$root.$route.meta.keepAlive);
			const store = useStore();
			// const showBar =ref(false);
			const showBar =ref(true);
			
			onMounted(() => {
				getUserInfo(proxy.$root.$route, store);
				// let zt =JSON.parse(sessionStorage.getItem('dlsb'));
				// console.log(zt);
				
				// if(zt == 'ios' || zt == 'android'){	//ios与android不显示顶部导航栏
				// 	showBar.value=false;
				// }else{
				// 	showBar.value=true;
					
				// }
				
			});
				
			return{
				keepAlive,
				showBar
			}
		},
		
  });

</script>
<style>

</style>
